import React from 'react';
import Logo from "../../images/pax_logo.png";

class PAX extends React.Component {
    render() {
        return (
            <div>
                <img src={Logo} height={'110px'} width={'250px'}/>
                <ul style={{color: 'black', width: '50%', backgroundColor: 'white'}}>
                    <li>Enforcer - Dev 8/19-9/19</li>
                    <li>VR Enforcer - West 8/19-9/19</li>
                    <li>VR Enforcer - West 8/18-9/18</li>
                    <li>VR Enforcer - West 8/17-9/17</li>
                </ul>
            </div>
        );
    }
}

export default PAX