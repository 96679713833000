import React from 'react';

class Jsx extends React.Component {
    render() {
        return (
            <div>
                <span>Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing</span>
                <span>Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing Testing</span>
            </div>
        );
    }
}

export default Jsx