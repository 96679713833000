import React from 'react';

class Java extends React.Component {
    render() {
        return (
            <div>
                Place work here.
            </div>
        );
    }
}

export default Java